import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { TextField } from '@material-ui/core'
import { useLocation } from '@reach/router'

const ConnectedSearchBox = connectSearchBox((props) => {
  const { refine, value, name, label } = props
  const [inputValue, setInputValue] = React.useState(value)
  const location = useLocation()

  React.useEffect(() => {
    refine(value)
    if (value === '') {
      setInputValue('')
    }
  }, [value, refine])

  React.useEffect(() => {
    const queryString = location.search
    if (queryString) {
      const urlParams = new URLSearchParams(queryString)
      setInputValue(urlParams.get('search'))
    }
  }, [location.search])

  return (
    <TextField
      fullWidth
      label={label}
      id={`discover-${label}`}
      name={name}
      type="text"
      value={inputValue}
      inputProps={{
        'aria-label': label,
      }}
      onChange={(e) => {
        setInputValue(e.target.value)
        if (typeof props.onChange === 'function') {
          props.onChange(e)
        }
      }}
    />
  )
})

export default ConnectedSearchBox
