import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import { Button } from '@klickmarketing/react-components'
import styled from 'styled-components'

const ClearRefinements = connectCurrentRefinements((props) => {
  const { items, refine } = props
  return (
    <ClearButton
      size="medium"
      color="secondary"
      onClick={(e) => {
        refine(items)
        if (typeof props.onClick === 'function') {
          props.onClick(e)
        }
      }}
      disabled={!items.length}
    >
      Clear
    </ClearButton>
  )
})

const ClearButton = styled(Button)`
  width: 100%;
  height: 100%;
`

export default ClearRefinements
