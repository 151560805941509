import React from 'react'
import { Heading } from '@klickmarketing/react-components'
import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core'
import { graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import DiscoverSection from '../components/DiscoverSection/DiscoverSection'
import Layout from '../components/Layout/Layout'

const DiscoverPage = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  return (
    <Layout
      seoProps={{
        title: t('Discover Articles & Videos', { ns: 'discover' }),
        description: t(
          'Explore our full collection of articles and videos featuring thought leaders and visionaries from health, life sciences, and beyond.',
          { ns: 'discover' }
        ),
      }}
    >
      <Box minHeight="100vh" mt={isDesktop ? 10 : 5}>
        <Container maxWidth="lg">
          <Box py={isDesktop ? 12 : 4} textAlign="center">
            <Heading component="h1" variant={isDesktop ? 'h1' : 'h2'}>
              <Trans ns="discover">Discover</Trans>
            </Heading>
          </Box>
        </Container>
        <DiscoverSection />
      </Box>
    </Layout>
  )
}

export default DiscoverPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "discover"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
