import React from 'react'
import { Box } from '@material-ui/core'
import { Pagination as MuiPagination, PaginationItem } from '@material-ui/lab'
import { useLocation } from '@reach/router'
import { Link as I18nLink, useI18next } from 'gatsby-plugin-react-i18next'

import { removeTrailingSlash } from '../../common/utils'

const Pagination = ({ currentRefinement, nbPages, refine, value }) => {
  const location = useLocation()
  const { originalPath } = useI18next()

  React.useEffect(() => {
    const page = new URLSearchParams(location.search).get('page')
    refine(page ? page : 1)
  }, [location.search, refine])

  if (!nbPages) {
    return null
  }

  return (
    <Box display="flex" justifyContent="center" pt={8}>
      <MuiPagination
        count={nbPages}
        page={currentRefinement}
        renderItem={(item) => {
          return (
            <PaginationItem
              component={I18nLink}
              to={getPaginationUrl({
                page: item.page,
                type: item.type,
                count: nbPages,
                originalPath,
              })}
              {...item}
            />
          )
        }}
      />
    </Box>
  )
}

const getPaginationUrl = ({ page, type, count, originalPath }) => {
  // We don't want to allow it to go beyond the pagination limits, mainly for crawlers.
  if (type === 'previous' && page === 0) return '#'
  if (type === 'next' && page === count + 1) return '#'

  const searchString = window.location.search
  const params = new URLSearchParams(searchString)

  page === 1 ? params.delete('page') : params.set('page', page)

  const hasNoParams = Array.from(params.entries()).length === 0
  return page === 1 && hasNoParams
    ? removeTrailingSlash(originalPath)
    : `${removeTrailingSlash(originalPath)}?${params.toString()}`
}

export default Pagination
