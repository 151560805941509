import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Heading } from '@klickmarketing/react-components'
import { Box, Grid } from '@material-ui/core'
import { useI18next } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { DEFAULT_LOCALE } from '../../../languages'
import MediaTile from '../MediaTile/MediaTile'

const FeaturedContent = () => {
  const [loadedData, setLoadedData] = React.useState()
  const { language } = useI18next()
  const { data } = useQuery(GET_FEATURED_DATA, {
    variables: {
      locale: language || DEFAULT_LOCALE,
    },
  })

  React.useEffect(() => {
    if (!data) return

    setLoadedData(data)
  }, [data])

  const homeData = loadedData && loadedData.homeCollection.items[0]

  return (
    <>
      <Box mb={4}>
        <Heading component="h2" variant="blurb1">
          Recommended
        </Heading>
      </Box>
      <Grid container direction="row" spacing={3}>
        {!homeData &&
          [...Array(6).keys()].map((_, index) => (
            <StyledGrid item xs={12} sm={6} md={4} key={index}>
              <MediaTile isLoading={true} />
            </StyledGrid>
          ))}
        {homeData &&
          homeData.featuredContentCollection.items.map((item) => (
            <StyledGrid item xs={12} sm={6} md={4} key={item.sys.id}>
              <MediaTile {...item} />
            </StyledGrid>
          ))}
      </Grid>
    </>
  )
}

const StyledGrid = styled(Grid)`
  margin-bottom: 48px;
`

export default FeaturedContent

const GET_FEATURED_DATA = gql`
  query GetHomePageData($locale: String!, $preview: Boolean) {
    homeCollection(
      preview: $preview
      where: { slug: "home" }
      limit: 1
      locale: $locale
    ) {
      items {
        featuredContentCollection(limit: 10) {
          items {
            ... on VideoPost {
              sys {
                id
              }
              __typename
              slug
              title
              description
              videoAsset {
                duration
                thumbnail {
                  url
                  height
                  width
                }
              }
              personsCollection(limit: 4) {
                items {
                  sys {
                    id
                  }
                  name
                }
              }
            }
            ... on ArticlePost {
              sys {
                id
              }
              __typename
              title
              slug
              description
              contentfulMetadata {
                tags {
                  id
                  name
                }
              }
              heroImage {
                image {
                  url
                  height
                  width
                }
              }
              personsCollection(limit: 4) {
                items {
                  sys {
                    id
                  }
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
