import React from 'react'
import {
  Configure,
  connectHits,
  connectPagination,
  connectStats,
  InstantSearch,
} from 'react-instantsearch-dom'
import { P, Section, ThemeProvider } from '@klickmarketing/react-components'
import { Box, Collapse, Container, Fade, Grid } from '@material-ui/core'
import { useLocation } from '@reach/router'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { ALGOLIA_ENV_PREFIX } from '../../../config'
import { DEFAULT_LOCALE, getAlgoliaIndexNameByLocale } from '../../../languages'
import { useAlgoliaSearch } from '../../contexts/AlgoliaSearch'
import MediaTile from '../MediaTile/MediaTile'
import Pagination from '../Pagination/Pagination'
import SearchBar from '../SearchBar/SearchBar'

import FeaturedContent from './FeaturedContent'

const AlgoliaPagination = connectPagination(Pagination)

const DiscoverSection = () => {
  const searchClient = useAlgoliaSearch()
  const { t, language } = useI18next()

  const indexName = getAlgoliaIndexNameByLocale(
    ALGOLIA_ENV_PREFIX,
    language || DEFAULT_LOCALE
  )

  const searchOptions = [
    {
      key: 'search',
      label: t('Search', { ns: 'discover' }),
      type: 'text',
      attribute: '',
    },
    {
      key: 'people',
      label: t('People', { ns: 'discover' }),
      type: 'menu',
      attribute: 'persons.name',
    },
    {
      key: 'event',
      label: t('Event', { ns: 'discover' }),
      type: 'menu',
      attribute: 'events.title',
    },
    {
      key: 'collection',
      label: t('Collection', { ns: 'discover' }),
      type: 'menu',
      attribute: 'collections.title',
    },
  ]

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <Configure hitsPerPage={12} />
      <SearchBar searchOptions={searchOptions} />
      <ThemeProvider themeType="onWhite">
        <StyledSection name={t('Content section')}>
          <Container maxWidth="lg">
            <HitsWrapper />
            <AlgoliaPagination />
          </Container>
        </StyledSection>
      </ThemeProvider>
    </InstantSearch>
  )
}

const Hits = connectHits(({ hits, nbHits }) => {
  const [isSearching, setIsSearching] = React.useState(false)
  const location = useLocation()

  React.useEffect(() => {
    setIsSearching(!!location.search)
  }, [location.search])

  return (
    <>
      <Collapse in={isSearching} collapsedSize={0} timeout={300}>
        <Box>
          <Fade in={isSearching}>
            <Box mb={3}>
              <P variant="blurb2">
                {nbHits} <Trans ns="discover">Results</Trans>
              </P>
            </Box>
          </Fade>
        </Box>
      </Collapse>
      <Grid container direction="row" spacing={4}>
        {hits.map((hit) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={hit.objectID}>
              <MediaTile {...hit} />
            </Grid>
          )
        })}
      </Grid>
      {hits.length === 0 && (
        <Box mt={4}>
          <FeaturedContent />
        </Box>
      )}
    </>
  )
})

const HitsWrapper = connectStats(Hits)

const StyledSection = styled(Section)`
  padding-top: 32px;
`

export default DiscoverSection
