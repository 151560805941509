import React from 'react'
import { connectMenu } from 'react-instantsearch-dom'
import { MuiSelectField } from '@klickmarketing/react-components'

const ConnectedMenu = connectMenu((props) => {
  const {
    items,
    currentRefinement,
    refine,
    inputProps,
    label,
    name,
    id,
    className,
    defaultRefinement,
    value,
  } = props

  React.useEffect(() => {
    refine(value)
  }, [value, refine])

  const options = items.map((item) => ({
    label: item.label,
    value:
      item.isRefined || item.label === currentRefinement
        ? currentRefinement
        : item.value,
  }))

  if (value && !options.find((item) => item.value === value)) {
    options.unshift({ label: value, value })
  }

  options.sort((x, y) => {
    if (x.label.toLowerCase() < y.label.toLowerCase()) {
      return -1
    }
    if (x.label.toLowerCase() > y.label.toLowerCase()) {
      return 1
    }
    return 0
  })

  return (
    <MuiSelectField
      label={label}
      id={id}
      name={name}
      disabled={!items.length}
      className={className}
      fullWidth
      defaultValue={defaultRefinement}
      value={currentRefinement || ''}
      onChange={(e) => {
        if (typeof props.onChange === 'function') {
          props.onChange(e)
        }
      }}
      options={options}
      inputProps={inputProps}
    />
  )
})

export default ConnectedMenu
