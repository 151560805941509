import React from 'react'
import { Section, ThemeProvider } from '@klickmarketing/react-components'
import { Container, Grid } from '@material-ui/core'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { debounce } from 'lodash'
import styled from 'styled-components'

import ClearRefinements from './ClearRefinements'
import ConnectedMenu from './ConnectedMenu'
import ConnectedSearchBox from './ConnectedSearchBox'

function paramsToObject(entries) {
  const result = {}
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}

const SearchBar = ({ searchOptions = [] }) => {
  const [searchValues, setSearchValues] = React.useState(
    searchOptions.reduce((a, v) => ({ ...a, [v.key]: '' }), {})
  )

  const location = useLocation()

  React.useEffect(() => {
    const queryString = location.search
    if (queryString) {
      const urlParams = new URLSearchParams(queryString)
      const newSearchValues = {}
      searchOptions.forEach(({ key }) => {
        newSearchValues[key] = urlParams.get(key)
      })
      setSearchValues(newSearchValues)
    } else {
      const newSearchValues = {}
      searchOptions.forEach(({ key }) => {
        newSearchValues[key] = ''
      })
      setSearchValues(newSearchValues)
    }
  }, [location.search, searchOptions])

  const setURL = (newParams) => {
    const searchString = window.location.search
    const currentParams = searchString
      ? paramsToObject(new URLSearchParams(searchString).entries())
      : {}

    const params = {
      ...currentParams,
      ...newParams,
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === '') {
        delete params[key]
      }
    })

    const query = new URLSearchParams(params).toString()
    navigate(`?${query}`, { replace: true })
  }

  const handleChange = React.useCallback((e) => {
    setURL({ [e.target.name]: e.target.value })
  }, [])

  const debouncedHandleChange = React.useMemo(
    () => debounce(handleChange, 300),
    [handleChange]
  )

  return (
    <ThemeProvider themeType="onBlack">
      <SearchSection name="Search content">
        <Container maxWidth="lg">
          <Grid container direction="row" spacing={3}>
            {searchOptions.map(({ key, label, type, attribute }) => {
              return type === 'text' ? (
                <Grid item xs={6} s={4} md={4} key={key}>
                  <ConnectedSearchBox
                    name={key}
                    label={label}
                    value={searchValues[key] || ''}
                    onChange={debouncedHandleChange}
                  />
                </Grid>
              ) : (
                <Grid item xs={6} s={4} md={2} key={key}>
                  <ConnectedMenu
                    id={`search-${key}`}
                    attribute={attribute}
                    label={label}
                    name={key}
                    value={searchValues[key] || ''}
                    fullWidth
                    limit={1000}
                    onChange={handleChange}
                    inputProps={{
                      label: label,
                      'aria-label': label,
                    }}
                  />
                </Grid>
              )
            })}
            <Grid item xs={6} s={4} md={2}>
              <ClearRefinements
                clearsQuery
                onClick={() =>
                  setURL(
                    searchOptions.reduce((a, v) => ({ ...a, [v.key]: '' }), {})
                  )
                }
              />
            </Grid>
          </Grid>
        </Container>
      </SearchSection>
    </ThemeProvider>
  )
}

const SearchSection = styled(Section)`
  padding-top: 32px;
  padding-bottom: 32px;
`

export default SearchBar
